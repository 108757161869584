<template>
  <div>
  <v-form v-model="form">
    <v-card>
      <v-card-title>
        2FA
      </v-card-title>
      <v-card-text>
          <v-row>
            <v-col
              cols=3
            >
              <v-text-field
                v-model="secret"
                label="Secret"
                :rules="[required('Secret')]"
                @change="updateOTP"
              />
              <p>
                <v-alert
                  v-if="otp === false"
                  border="bottom"
                  color="red"
                  type="error"
                >
                  SECRET không hợp lệ !!!
                </v-alert>
              </p>
            </v-col>
            <v-col
              cols=3
            >
              <v-text-field
                v-model="otp"
                label="OTP"
                readonly
              />
              <p>
                <v-alert
                  v-if="otp === false"
                  type="info"
                >
                  TOTP: {{countdown}}s
                </v-alert>
              </p>
            </v-col>
          </v-row>
      </v-card-text>
      <v-card-actions>
      </v-card-actions>
      <loading :status="loader" />
    </v-card>
  </v-form>
  </div>
</template>

<script>
import validations from '@/helpers/validations.js';
import {OTP} from '@/helpers/totp';
import { ThongBao } from '@/helpers'
import loading from '@/components/base/loading.vue';
export default {
  components:{
    loading,
  },
  data () {
    return {
      form:false,
      secret:undefined,
      content: undefined,
      otp: undefined,
      loader:false,
      interval:undefined,
      countdown:0,
    }
  },
  watch:{
    secret(v){
      if(!!v) this.secret = v.trim();
    }
  },
  methods:{
    ...validations,
    updateOTP(){
      if(this.secret){
        this.otp = OTP(this.secret);
        if(this.otp === false){
          this.secret = undefined;
        }
      }
    },
    checkOTP(){
        const epoch = Math.round(new Date().getTime() / 1000.0);
        const countDown = 30 - (epoch % 30);
        if (epoch % 30 == 0) this.updateOTP();
        this.countdown = countDown;
    },
  },
  beforeDestroy(){
    clearInterval(this.interval);
    this.interval = undefined;
  },
  mounted(){
    this.interval = setInterval(this.checkOTP, 1000);
  }
}
</script>

<style>

</style>